import { axiosAuthorized } from "../../api/config"

export const getBranches = async () => {
  try {
    const response = await axiosAuthorized.get(`/branches/all_locations`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addBranch = async (initialState, branchId = null) => {
  try {
    const requestData = {
      ...initialState,
    };

    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = branchId
      ? `/branches/update_location/${branchId}`
      : `/branches/create_location`;

    const response = await axiosAuthorized[branchId ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteBranch = async (branchId) => {
  try {
    const response = await axiosAuthorized.delete(`/branches/delete_location/${branchId}`);
    return response;
  } catch (error) {
    throw error;
  }
}