import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import {
  ImageInput,
  ShowImage,
  TextAreaInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import { addCardBanner } from "../../utils/AboutUtils/AboutUtils";

const CardBannerModal = ({
  setOpen,
  setDatasFn,
  editData = null,
  setEditData,
}) => {
  const [err, setErr] = useState({ global: "" });
  const [image, setImage] = useState(editData?.banner_card_image || null);
  const [loading, setLoading] = useState(false);
  const initialsState = {
    description: editData?.description || "",
  };
  const [formData, handleFormData, clearForm] = useForm(initialsState);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.description.trim().length)
      return setErr({ global: "Invalid Description" });
    if (!image) return setErr({ global: "Select Image" });
    setErr({ global: "" });
    setLoading(true);
    addCardBanner(formData, image, editData?._id)
      .then((res) => {
        setDatasFn();
        setImage(null);
        setOpen(false);
        clearForm();
        setEditData(null);
        toast(`Card Banner ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`Card Banner ${editData ? "Edit Failed" : "Add Failed"}`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className=" flex  w-fit overflow-y-auto max-h-[400px] border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Card Banner"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5 flex-col">
          <TextAreaInput
            data={formData}
            formName={"description"}
            name="Description"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-fit h-fit justify-between gap-5">
          <ImageInput imageName={"Banner Image"} setImage={setImage} />
          <ShowImage image={image} setImage={setImage} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setEditData(null);
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardBannerModal;
