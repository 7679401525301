import React, { useState } from 'react'
import { TiArrowLeft } from 'react-icons/ti';
import PasswordInput from '../../Common/FormComponents/PasswordInput/PasswordInput';
import { submitNewPassword } from '../../../utils/loginUtils/loginUtils';

const NewPasswordField = ({loginData,handleLoginData,setErr,clearForm, setForgot,setEmailVerfied,setOtpVerified,loading,setLoading,err}) => {
    const [changed, setChanged] = useState("");
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!(/^.{8,}$/).test(loginData.newPassword)) {
            setErr({ global: "Invalid Password" });
            return;
        }
        setLoading(true);
        submitNewPassword(loginData.email, loginData.newPassword, setErr)
        .then(res=>{
            setChanged("Password Changed");
            setTimeout(() => {
                clearForm();
                setForgot(false);
                setEmailVerfied(false);
                setOtpVerified(false);

            }, 1000)
        }).catch(err=>console.log(err)).finally(()=>{setLoading(false)});
    }
    return (
      <form
        onSubmit={(e) => handleSubmit(e)}
        className="flex w-full h-full justify-center items-center"
      >
        <div className="flex w-4/5 lg:w-1/2 h-fit flex-col gap-5 px-10 lg:px-0">
          <PasswordInput
            data={loginData}
            handleInput={handleLoginData}
            name="Password"
            formName={"newPassword"}
            setError={setErr}
            labelColor="text-black"
          />
          <span
            onClick={() => {
              setErr({ global: "" });
              setForgot(false);
              setEmailVerfied(false);
              setOtpVerified(false);
            }}
            style={{
              color: "transparent",
              WebkitTextFillColor: "transparent",
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
            }}
            className="w-full flex gap-1 items-center justify-end text-sm cursor-pointer hover:text-primary"
          >
            <TiArrowLeft className="black" /> back
          </span>
          {err.global && (
            <div className="flex w-full h-fit text-red-600 justify-center items-center font-semibold text-sm">
              {err.global}
            </div>
          )}
          {changed.length > 0 && (
            <div className="flex w-full h-fit text-green-600 justify-center items-center font-semibold text-sm">
              {changed}
            </div>
          )}
          <button
            disabled={loading}
            onClick={(e) => {
              handleSubmit(e);
            }}
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            className="w-full h-fit py-1 justify-center items-center px-2 text-white  rounded-md"
          >
            {loading ? "Loading..." : "Submit"}
          </button>
        </div>
      </form>
    );
}

export default NewPasswordField