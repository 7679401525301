import { Layout } from './layout';
import { Route, Routes } from 'react-router-dom';
import { About, Categories, HealthCards, Login, HealthCardProfiles } from './pages';
import { AuthProtected, UserProtected } from "./components/ProtectedRoutes/UserProtected";
import { useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import { saveUser } from "./redux/userSlice";

function App() {
  const dispatch = useDispatch();
  const saveUserFromLocalStorage = useCallback(() => {
    const healthCardAdmin = JSON.parse(localStorage.getItem("healthCardAdmin"));
    if (healthCardAdmin) {
      dispatch(saveUser(healthCardAdmin));
    }
  }, [dispatch]);
  useEffect(() => {
    saveUserFromLocalStorage();
  }, [saveUserFromLocalStorage]);
  return (
    <Routes>
      <Route path="/" element={<UserProtected element={<Layout />} />}>
      <Route index={true} element={<HealthCards/>}/>
      <Route path='/profiles' element={<HealthCardProfiles />}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/categories' element={<Categories/>}/>
      </Route>
      <Route path="/login" element={<AuthProtected element={<Login />} />} />
    </Routes>
  );
}

export default App;
