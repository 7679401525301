import React from "react";
import "./RadioInput.css"; 

const RadioInput = ({
  name = "",
  formName,
  data,
  handleInput,
  options = [],
  setError,
  hide = false,
}) => {
  return (
    <div className="radio-container">
      {name.length > 0 && (
        <label htmlFor={formName} className="label">
          {name}{" "}
          <span hidden={hide} className="required">
            *
          </span>
        </label>
      )}
      <div className="radio-options" onClick={() => setError({ global: "" })}>
        {options.map((option, index) => (
          <div key={index} className="radio-option">
            <input
              type="radio"
              id={`${formName}-${index}`}
              name={formName}
              value={option.value}
              checked={data[formName] === option.value}
              onChange={handleInput}
              className="radio-input"
            />
            <label htmlFor={`${formName}-${index}`} className="radio-label">
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioInput;
