import { axiosAuthorized } from "../../api/config"

export const getAbout = async () => {
  try {
    const response = await axiosAuthorized.get(`/getaboutcard`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addAbout = async (initialState, about_card_image , id = null) => {
  try {
    const requestData = {
      ...initialState,
      about_card_image 
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/aboutcard/update_aboutcard/${id}`
      : `/aboutcard/create_aboutcard`;
    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}


export const getCardBanner = async () => {
  try {
    const response = await axiosAuthorized.get(`/getbannercard`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addCardBanner = async (initialState, banner_card_image , id = null) => {
  try {
    const requestData = {
      ...initialState,
      banner_card_image 
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/bannercard/update_bannercard/${id}`
      : `/bannercard/create_bannercard`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}


