import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useForm } from "../../hooks";
import {
  TextAreaInput,
  TextInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import { addCategories } from "../../utils/CategoriesUtils/CategoriesUtils";

const CategoriesModal = ({
  setOpen,
  editData = null,
  setEditData = () => {},
  setDatasFn = ()=> {},
}) => {
  const initialState = {
    type: editData?.type || "",
    description: editData?.description || "",
  };
  const [formData, handleFormData, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });
  const handleSubmit = () => {
      setErr({ global: "" });
      setLoading(true);
    addCategories(
        formData,
        editData?._id
      )
        .then((res) => {
          setDatasFn();
          clearForm();
          setEditData(null);
          setOpen(false);
          toast(`Health Card ${editData ? "Edited" : "Added"}`, {
            type: "success",
            autoClose: 3000,
          });
        })
        .catch((err) => {
          console.error(err);
          toast(`Health Card ${editData ? "Edit Failed" : "Adde Failed"}`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
  };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Patient Details"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"type"}
            name={"Category"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextAreaInput
            formName={"description"}
            name={"Description"}
            hide={true}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesModal;
