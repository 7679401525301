import React from "react";
import { useMemo } from "react";
import Table from "../Common/Table/Table";

const HealthCardsTable = ({ datas, setEditData, setOpen, setSelectedUser }) => {
  const headings = useMemo(
    () => [
      "C Number",
      "Name",
      // "Region",
      "Number",
      "Email",
      // "C Name",
      "Type",
      // "Offer",
      "Free-Left",
      "Discount-Left",
      "Points",
      "Joining",
      // "Expiring",
      "Offer / Renew",
      "Transactions",
    ],
    []
  );
  return (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.card_number}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 ">{data?.user_id?.name}</div>
          </td>
          {/* <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.user_id?.region}</div>
          </td> */}
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {data?.user_id?.phone_number}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {data?.user_id?.email_id}
            </div>
          </td>
          {/* <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {data?.card_id?.card_name}
            </div>
          </td> */}
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {data?.card_id?.card_type}
            </div>
          </td>
          {/* <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {data?.card_id?.available_offer}
            </div>
          </td> */}
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {data?.free_offers_used || 0}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {data?.discount_used || 0}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {data?.credit_point || 0}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.date}</div>
          </td>
          {/* <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.expired_date}</div>
          </td> */}
          <td className="px-6 py-4 whitespace-nowrap">
            <button
              className="flex justify-center items-center gap-2 text-xs border rounded-md bg-primary text-white p-2"
              onClick={() => {
                setEditData(data);
                setOpen(true);
              }}
            >
              Offer / Renew
            </button>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <button
              className="flex justify-center items-center gap-2 text-xs border rounded-md bg-green-600 text-white p-2"
              onClick={() => {setSelectedUser(data);}}
            >
              Transactions
            </button>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default HealthCardsTable;
