import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";

export const UserProtected = ({element}) => {
    const { healthCardAdmin } = useSelector((state) => state.userReducer);
    if (healthCardAdmin) {
        return <>{element}</>;
    }
    return <Navigate to={"/login"} replace />;
}

export const AuthProtected = ({element}) => {
    const { healthCardAdmin } = useSelector((state) => state.userReducer);
    if (healthCardAdmin) {
      return <Navigate to={"/"} replace />;
    }
    return <>{element}</>;
}
