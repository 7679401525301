import React from "react";
import AboutCard from "./AboutCard";
import CardBanner from "./CardBanner";

const AboutMainSection = () => {
  return (
    <>
      <div className=" flex w-full items-center gap-5 md:gap-0 flex-col">
        <div className="flex w-full h-fit text-xl font-semibold">
          About
        </div>
        <div className="flex flex-col w-full mt-5 gap-5">
          <AboutCard />
          <CardBanner/>
        </div>
      </div>
    </>
  );
};

export default AboutMainSection;
