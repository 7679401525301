import React from "react";
import { useState } from "react";
import { NoDataFound, SearchInput } from "../Common";
import HealthCardsTable from "./HealthCardsTable";
import { useCallback } from "react";
import { useEffect } from "react";
import { getHealthCardsProfiles } from "../../utils/HealthCardsUtils/HealthCardsUtils";
import HealthCardsRenewalAlert from "./HealtCardsRenewelAlert";
import { LuPlusCircle } from "react-icons/lu";
import { BiFilterAlt } from "react-icons/bi";
import { BsArrowLeftShort } from "react-icons/bs";
import HealthCardsBulkRenewalAlert from "./HealthCardsBulkRenewalAlert";
import HealthCardsFilter from "./HealthCardsFilter";
import TransactionList from "./TransactionList";

const HealthCardsProfilesMainSection = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState([]);
  const [filteredDatas, setFilteredDatas] = useState([]);
  const setDatasFn = useCallback(() => {
    getHealthCardsProfiles()
      .then((res) => {
        setDatas(res?.data?.data);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  const [filterData, setFileterData] = useState({
    joining_date: null,
    expiry_date: null,
    card_type: null,
    regions: [],
  });
  const [openFilter, setOpenFilter] = useState(false);
  const [bulkRenew, setBulkRenew] = useState(false);
  useEffect(() => {
    const filteredData = datas?.filter((item) => {
      if (
        filterData.card_type &&
        item?.card_id?.card_type !== filterData.card_type
      )
        return false;

      if (filterData?.joining_date && item?.date) {
        const date1 = new Date(item?.date.split("/").reverse().join("/"));
        const date2 = new Date(
          filterData?.joining_date.split("/").reverse().join("/")
        );
        if (date1 < date2) return false;
      }

      if (filterData?.expiry_date && item?.expiry_date) {
        const date1 = new Date(
          item?.expiry_date.split("/").reverse().join("/")
        );
        const date2 = new Date(
          filterData?.expiry_date.split("/").reverse().join("/")
        );
        if (date1 > date2) return false;
      }

      if (
        filterData.regions.length > 0 &&
        !filterData.regions.includes(item?.user_id?.region)
      )
        return false;

      return true;
    });
    setFilteredDatas(filteredData);
  }, [datas, filterData]);
  const [selectedUser, setSelectedUser] = useState(null);
  return (
    <>
      {open && (
        <HealthCardsRenewalAlert
          setOpen={setOpen}
          editData={editData}
          setEditData={setEditData}
        />
      )}
      {bulkRenew && <HealthCardsBulkRenewalAlert setOpen={setBulkRenew} />}
      {openFilter && (
        <HealthCardsFilter
          selected={filterData}
          setOpen={setOpenFilter}
          setSelected={setFileterData}
        />
      )}
      <div className=" flex w-full items-center gap-5 md:gap-0">
        <div className="flex w-fit h-fit text-xl font-semibold flex-shrink-0">
          Health Cards Profiles
        </div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full p-3 flex-col">
        <div className="flex w-full justify-between items-center h-fit">
          <span className="font-semibold text-base">Informations</span>
          <div className="flex gap-2">
            <button
              style={{
                background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
              }}
              className={` flex text-white text-sm rounded-md gap-2 p-2  items-center `}
              onClick={() => {
                setOpenFilter(true);
              }}
            >
              <span>Filter</span>
              <span>
                <BiFilterAlt />
              </span>
            </button>
            <button
              style={{
                background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
              }}
              className={` flex text-white text-sm rounded-md gap-2 p-2  items-center `}
              onClick={() => setBulkRenew(true)}
            >
              <span>Offer/Renewal</span>
              <span>
                <LuPlusCircle />
              </span>
            </button>
          </div>
        </div>
        {selectedUser && (
          <button
            className={`w-fit flex text-sm rounded-md gap-2 p-2 text-primary items-center`}
            onClick={() => setSelectedUser(null)}
          >
            <span>
              <BsArrowLeftShort />
            </span>
            <span>Back</span>
          </button>
        )}
        {selectedUser ? (
        <TransactionList selectedUser={selectedUser?.user_id} />
        ) : filteredDatas?.length > 0 ? (
        <HealthCardsTable
          setDatasFn={setDatasFn}
          datas={filteredDatas}
          setSelectedUser={setSelectedUser}
          setEditData={setEditData}
          setOpen={setOpen}
        />
        ) : (
        <div className="flex mt-10">
          <NoDataFound data={"Health Cards Profiles"} />
        </div>
        )}
      </div>
    </>
  );
};

export default HealthCardsProfilesMainSection;
